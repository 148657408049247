import { type FC } from 'react';
import styles from './index.module.scss';
// import { Button, Divider } from '@douyinfe/semi-ui';
// import One from '../../components/One/One';
// import Two from '../../components/Two/Two';

// const Resume: FC = () => {
//   const [users, setUsers] = useState<
//     Array<{ id: string; name: string; email: string; phone: string }>
//   >([]);
//   const throwError = () => {
//     void fetch('https://jsonplaceholder.typicode.com/users')
//       .then(async (response) => await response.json())
//       .then((json) => {
//         setUsers(json);
//       });
//   };
//   return (
//     <div className={styles.main}>
//       <div className={styles.container}>
//         <h2>父组件</h2>
//         <button onClick={throwError}>fetch</button>
//         {/* <Divider margin={30} /> */}
//         <One />
//         <Two />
//         <div>
//           {users.map((el) => (
//             <div key={el.id}>
//               <div>{el.name}</div>
//               <div>{el.email}</div>
//               <div>{el.phone}</div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

const Resume: FC = () => {
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.base}>
          <div className={styles.text}>
            <div className={styles.name}>向兴强 前端开发工程师</div>
            <div className={styles.item}>
              天津理工大学 | 软件工程专业 | 2024届
            </div>
            <div className={styles.itemWithIcon}>
              <div className={styles.block}>
                <img
                  width={20}
                  height={20}
                  src="../../../public/blog.png"
                  alt=""
                  style={{ marginRight: 3 }}
                />
                <div>https://xxq-me.cn</div>
              </div>

              <div className={styles.block}>
                <img
                  width={23}
                  height={23}
                  src="../../../public/wechat2.png"
                  alt=""
                  style={{ marginRight: 2 }}
                />
                <div>huengIO</div>
              </div>
            </div>
            <div className={styles.itemWithIcon}>
              <div className={styles.block}>
                <img
                  width={20}
                  height={20}
                  src="../../../public/email.png"
                  alt=""
                  style={{ marginRight: 2 }}
                />
                <div>huengemail@163.com</div>
              </div>
              <div className={styles.block}>
                <img
                  width={20}
                  height={20}
                  src="../../../public/phone.png"
                  alt=""
                  style={{ marginRight: 3 }}
                />
                <div>18728633002</div>
              </div>
            </div>
          </div>
          <div className={styles.avatar}>
            <img src="../../../public/hi.png" alt="" width={150} height={150} />
          </div>
        </div>
        <div className={styles.intern}>
          <div className="title">工作经历</div>
          <div className={styles.hello}>
            <img
              src="../../../public/hello.png"
              alt=""
              width={35}
              height={35}
            />
            <div className={'title2'}>哈啰出行 数科事业部 2023.11-至今</div>
          </div>

          <div className={styles.TAL}>
            <img src="../../../public/TAL.png" alt="" width={35} height={35} />
            <div className={'title2'}>
              好未来 学而思 (实习) 境外产研 2023.4 - 2023.8
            </div>
          </div>
          <div className={styles.zhihu}>
            <img
              src="../../../public/zhihu.png"
              alt=""
              width={35}
              height={35}
            />
            <div className={'title2'}>知乎 技术中台 (实习) 2022.6 - 2022.9</div>
          </div>
        </div>
        <div className={styles.project}>
          <div className="title">项目</div>
          <div className="title2">⚔️ 前端监控</div>
        </div>
        <div className={styles.tech}>
          <div className="title">技能</div>
          <div className="title2">⚔️ 前端监控</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
