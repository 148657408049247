import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import Home from './pages';
import Resume from './pages/Resume/resume';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/index" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
        </Routes>
      </BrowserRouter>
      <Home />
    </div>
  );
}

export default App;
